import * as React from 'react';

import Container from '../../../common/container';
import EmpoweringIcon from '../../../assets/icons/empowering.svg';

import * as s from './styles.module.scss';

const EmpoweringBuilders = () => {
  return (
    <section className={s.empoweringBuilders}>
      <Container>
        <div className={s.empoweringBuilders_content}>
          <div className={s.empoweringBuilders_icon}>
            <EmpoweringIcon />
          </div>
          <div className={s.empoweringBuilders_wrapper}>
            <h2 className={s.empoweringBuilders_title}>
              Empowering Builders for Ongoing Success
            </h2>
            <p className={s.empoweringBuilders_text}>
              Let's step beyond the strategy. We work with your existing product,
              services, and customer success teams to build for sustainable
              outcomes. Our solutions can include discovery, outcome alignment,
              tech requirements, solution architecture, development, measuring
              value, change management, and ongoing training and enablement. So whether
              you're building a targeted offering or just working blockchain into your
              digital transformation, we've got the right partnership for you.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default EmpoweringBuilders;
