import * as React from 'react';

import Container from '../../../common/container';
import OurJourneyIcon from '../../../assets/icons/journey.svg';

import * as s from './styles.module.scss';

const OurJourney = () => {
  return (
    <section className={s.ourJourney}>
      <Container>
        <div className={s.ourJourney_content}>
          <div className={s.ourJourney_wrapper}>
            <h5 className={s.ourJourney_title}>Bring your own tech or use ours</h5>
            <p className={s.ourJourney_text}>
              Edunomic is everywhere you are, and can work with your unique tech
              stack. We have our own public testnet blockchain (Edunomic Chain)
              for development and user acceptance testing, or can work with your
              resources in any blockchain, cloud, or private on-prem solution. 
            </p>
          </div>
          <div className={s.ourJourney_icon}>
            <OurJourneyIcon />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OurJourney;
