import * as React from 'react';

import Container from '../../../common/container';
import WelcomeIcon from '../../../assets/icons/welcome.svg';

import * as s from './styles.module.scss';

const Welcome = () => {
  return (
    <section className={s.welcome}>
      <Container>
        <div className={s.welcome_content}>
          <div className={s.welcome_icon}>
            <WelcomeIcon />
          </div>
          <div className={s.welcome_wrapper}>
            <h2 className={s.welcome_title}>We are Edunomic</h2>
            <p className={s.welcome_description}>
              Complete solutions for your On-Chain future
            </p>
            <p className={s.welcome_text}>
              If blockchain is popping up in your product roadmap or you've got
              a customer advisory council that wants to know how you plan to work
              on-chain, you need more than just a developer - you need a partner.
            </p>
            <p className={s.welcome_text}>
              Edunomic is your complete solutions provider in the blockchain space.
              We don't just build on chain, we understand your business needs and
              goals and apply our expertise in blockchain, enterprise architecture,
              change management, business planning, cloud, and training and enablement to
              provide a complete solution for customer success.
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Welcome;
