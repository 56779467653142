import * as React from 'react';

import Layout from '../layout';
import Hero from '../components/home/hero';
import Welcome from '../components/home/welcome';
import EdTech from '../components/home/ed-tech';
import EmpoweringBuilders from '../components/home/empowering-builders';
import OurJourney from '../components/home/our-journey';
import ContactUs from '../components/shared/contact-us';
import Supporters from '../components/shared/supporters';

const HomePage = () => {
  return (
    <Layout>
      <Hero />
      <Welcome />
      <EdTech />
      <EmpoweringBuilders />
      <OurJourney />
      <ContactUs />
      <Supporters />
    </Layout>
  );
};

export default HomePage;
