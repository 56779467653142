// extracted by mini-css-extract-plugin
export var getInvolved = "styles-module--getInvolved--W39Ol";
export var getInvolved_block = "styles-module--getInvolved_block---Hdbg";
export var getInvolved_content = "styles-module--getInvolved_content--LXuZL";
export var getInvolved_form = "styles-module--getInvolved_form--U9+yF";
export var getInvolved_formContainer = "styles-module--getInvolved_formContainer--VXC76";
export var getInvolved_icon = "styles-module--getInvolved_icon--T6iSm";
export var getInvolved_link = "styles-module--getInvolved_link--ZCcna";
export var getInvolved_socialLinks = "styles-module--getInvolved_socialLinks--FB5C5";
export var getInvolved_text = "styles-module--getInvolved_text--U14db";
export var getInvolved_title = "styles-module--getInvolved_title--3+iJT";
export var getInvolved_wrapper = "styles-module--getInvolved_wrapper--hUkSf";