import * as React from 'react';

import Container from '../../../common/container';
import GetInvolvedIcon from '../../../assets/icons/get-involved.svg';

import * as s from './styles.module.scss';

const ContactUs = () => {
  return (
    <section className={s.getInvolved}>
      <Container>
        <div className={s.getInvolved_content}>
          <div className={s.getInvolved_icon}>
            <GetInvolvedIcon />
          </div>
          <div className={s.getInvolved_wrapper}>
            <h3 className={s.getInvolved_title}>Get In Touch</h3>
            <p className={s.getInvolved_text}>
              Ready to work, or trying to figure out your next steps? Get in
              touch with us and let's talk through it together.
            </p>
            <div className={s.getInvolved_formContainer}>
              <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" className={s.getInvolved_form}>
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <input type="hidden" name="subject" value="Inquirity from edunomic.io" />
                <fieldset>
                  <input placeholder="Your Name" type="text" name="name" required autofocus />
                </fieldset>
                <fieldset>
                  <input placeholder="Your Email Address" type="email" name="email" required />
                </fieldset>
                <fieldset>
                  <input placeholder="Your Phone Number (optional)" type="tel" name="tel" />
                </fieldset>
                <fieldset>
                  <input placeholder="Your Title" type="text" name="title" required />
                </fieldset>
                <fieldset>
                  <input placeholder="Your Company" type="text" name="company" required />
                </fieldset>
                <fieldset>
                  <textarea placeholder="Tell us a little about what you're looking for..." name="message" required></textarea>
                </fieldset>
                <fieldset>
                  <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;
