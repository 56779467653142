import * as React from 'react';

import Container from '../../../common/container';

import * as s from './styles.module.scss';

const EdTech = () => {
  return (
    <section className={s.edTech}>
      <Container>
        <div className={s.edTech_content}>
          <h3 className={s.edTech_title}>Solutions for every market</h3>
          <p className={s.edTech_text}>
            From EdTech to FinTech, DeFi to DeSci, on-prem to SaaS, Edunomic
            knows your business. We work across all verticals to build
            curated, realistic go to market products and services for your customer
            and channel needs.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default EdTech;
